import axios from 'axios';

const apiUrl = process.env.BACKEND_ENDPOINT;

export interface CatalogDetail {
  isOnline: boolean;
  highlighted: boolean;
  id: string;
  description: string;
  isFree: boolean;
  link: string;
  name: string;
  imageUrl: string;
  tags: string[];
  targets: string[];
  slug: string;
  created_at: string;
  updated_at: string;
  active: boolean;
  category: {
    id: string;
    slug: string;
    name: string;
    description: string;
    hidden: boolean;
    icon: string;
    orderIndex: number;
  };
  department: {
    id: string;
    slug: string;
    name: string;
    shortName: string;
    mission: string;
    vision: string;
    values: string;
    hidden: boolean;
    active: boolean;
  };
  info: {
    icon: string;
    id: string;
    title: string;
    text: string;
    orderIndex: number;
  }[];
  steps: {
    channel: string;
    id: string;
    name: string;
    description: string;
    htmlContent: string;
    orderIndex: number;
    documents: string[];
  }[];
  departmentSections: {
    id?: string;
    name: string;
    address: string;
    postalCode: string;
    district: string;
    city: string;
    state: string;
    countryCode: string;
    openingTime: string;
    mapUrl: string;
    active?: boolean;
    complement?: string;
    department: {
      id: string;
      slug: string;
      name: string;
      shortName: string;
      mission: string;
      vision: string;
      values: string;
      hidden: boolean;
      active: boolean;
    };
  }[];
  averageRatings?: {
    count: number;
    average: number;
  };
}

const departmentSectionsMock = [
  {
    id: '01',
    name: 'Detran',
    address: 'Rua Alvaro Abranches, 800',
    postalCode: '14405-500',
    district: 'Centro',
    city: 'Ribeirão Preto',
    state: 'Pernambuco',
    countryCode: '123',
    openingTime: '10 min',
    mapUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3736.852566561649!2d-47.41426652387048!3d-20.512269256705963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94b0a7aa9cb350c1%3A0xd3b4a6434cc81b30!2sR.%20Francisco%20Evandro%20Corr%C3%AAia%2C%20Franca%20-%20SP%2C%2014405-450!5e0!3m2!1spt-BR!2sbr!4v1733327858186!5m2!1spt-BR!2sbr',
    active: true,
    complement: 'complemento',
    department: {
      id: '1',
      slug: 'slug',
      name: 'name dep',
      shortName: 'detran',
      mission: 'mission',
      vision: 'vision',
      values: 'values',
      hidden: false,
      active: true,
    },
  },
  {
    id: '02',
    name: 'Seduc',
    address: 'Rua Francisco Evando Correa, 790',
    postalCode: '14405-500',
    district: 'Centro',
    city: 'Campinas',
    state: 'Pernambuco',
    countryCode: '123',
    openingTime: '10 min',
    mapUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3736.852566561649!2d-47.41426652387048!3d-20.512269256705963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94b0a7aa9cb350c1%3A0xd3b4a6434cc81b30!2sR.%20Francisco%20Evandro%20Corr%C3%AAia%2C%20Franca%20-%20SP%2C%2014405-450!5e0!3m2!1spt-BR!2sbr!4v1733327858186!5m2!1spt-BR!2sbr',
    active: true,
    complement: 'complemento',
    department: {
      id: '2',
      slug: 'slug',
      name: 'name dep',
      shortName: 'detran',
      mission: 'mission',
      vision: 'vision',
      values: 'values',
      hidden: false,
      active: true,
    },
  },
];

const infoMock = [
  {
    icon: 'fa-check',
    id: '1234',
    title: 'Quem pode utilizar este serviço?',
    text: 'Cidadão pernambucano que dispõe de laudo médico indicando deficiência e o acompanhante da pessoa com deficiência.',
    orderIndex: 1,
  },
  {
    icon: 'fa-circle-info',
    id: '1235',
    title: 'Quanto custa para acessar o serviço?',
    text: 'A solicitação da emissão de Passe Livre Intermunicipal é gratuita.',
    orderIndex: 2,
  },
];

const stepsMock = [
  {
    channel: 'online',
    id: '01257',
    name: 'Faça o login no Gov Cidadão',
    description:
      'O login é a primeira etapa para o acesso aos serviços digitais oferecidos pelo Gov Cidadão. Para acessar os serviços digitais do Gov Cidadão, você precisa fazer o login. Existem três maneiras de fazer isso: através de sua ID, através do cadastro direto no Portal Gov Cidadão ou através de sua conta do Gov.br. Destacamos que ao fazer o login com a ID, você receberá imediatamente o selo Opala, que dá acesso a todos os serviços disponíveis.',
    htmlContent: '',
    orderIndex: 1,
    documents: [],
  },
  {
    channel: 'online',
    id: '01258',
    name: 'Preencha o formulário eletrônico',
    description:
      'Preencha o formulário eletrônico com os dados solicitados O formulário eletrônico deverá ser preenchido com os seguintes dados do solicitante: nome, endereço, número de CPF, número de RG com órgão emissor, data de nascimento, sexo, nacionalidade, município, unidade federativa, nome completo da mãe, nome completo do pai, endereço de e-mail e telefone de contato.',
    htmlContent: '',
    orderIndex: 2,
    documents: ['CPF', 'Comprovante de endereço'],
  },
];

export async function getCatalog(
  accessToken?: string,
  slug?: string
): Promise<CatalogDetail> {
  const response = await axios.get(`${apiUrl}/v1/catalog/${slug}/load`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const realData = response.data;

  const mergedDepartmentSections = departmentSectionsMock.map((mockItem) => {
    const realItem = realData.departmentSections?.find(
      (real: CatalogDetail['departmentSections'][number]) =>
        real.id === mockItem.id
    );
    return { ...mockItem, ...realItem };
  });

  const additionalRealData = realData.departmentSections?.filter(
    (realItem: CatalogDetail['departmentSections'][number]) =>
      !departmentSectionsMock.some((mockItem) => mockItem.id === realItem.id)
  );
  //TODO remover o mock em produçao

  return {
    ...realData,
    info: realData.info?.length ? realData.info : infoMock,
    steps: realData.steps?.length ? realData.steps : stepsMock,
    departmentSections: [
      ...mergedDepartmentSections,
      ...(additionalRealData || []),
    ],
  };
}
