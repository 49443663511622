import { styled } from '@mui/material';
import MuiButton from '@mui/material/Button';
import MuiBox from '@mui/material/Box';

export const Button = styled(MuiButton)`
  padding: 0px 25px;
  text-transform: capitalize;
  background-color: #143971;
`;

export const SearchBarContainer = styled(MuiBox)`
  max-width: 100%;
  margin: 0 0 0 auto;
  width: 100%;
`;

export const SearchBarBlock = styled(MuiBox)`
  display: flex;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #bbbbbb;
`;

export const SearchBarInputContainer = styled(MuiBox)`
  display: flex;
  align-items: center;
  width: 100%;

  & svg {
    margin: 0 10px 0 0;
  }
`;

export const SearchBarInput = styled('input')`
  background: transparent;
  border: none;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  margin: 0 0 0 10px;
  width: 100%;
  height: 35px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #bbbbbb;
    opacity: 1;
  }
`;