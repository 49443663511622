import React, { FC } from 'react';
import { TitleSection } from '../../../components/title-sections/title-sections';
import Slider from 'react-slick';
import { TabsContent } from '../../../components/news/tabs-content';

import NewspaperIcon from '@mui/icons-material/Newspaper';
import VideocamIcon from '@mui/icons-material/Videocam';
import MicNoneIcon from '@mui/icons-material/MicNone';
import PhotoIcon from '@mui/icons-material/Photo';
import LanguageIcon from '@mui/icons-material/Language';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { MaterialContentContainer } from './tab-news-content/material-content/material-content.container';
import {
  VideoContentContainer,
  VideoNewsContent,
} from './tab-news-content/video-content/video-content.container';
import {
  PodcastContentContainer,
  PodcastNewsContent,
} from './tab-news-content/podcast-content/podcast-content.container';
import { AllContentContainer } from './tab-news-content/all-content/all-content.container';
import { GalleryContentContainer } from './tab-news-content/gallery-content/gallery-content.container';

export interface PodcastNewsProps {
  podcastNewsList: PodcastNewsContent;
  page: number;
  totalPages: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

export interface VideoNewsProps {
  newsVideoList: VideoNewsContent;
  page: number;
  totalPages: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

export const GovNews: FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 8000,
    autoplay: true,
    cssEase: 'linear',
    nextArrow: <></>,
    prevArrow: <></>,
  };

  const govPrograms = [
    {
      img: `${process.env.PUBLIC_URL}/images/gov-news/news-banner-01.png`,
      alt: 'Programa Ronda do Bairro',
    },
    {
      img: `${process.env.PUBLIC_URL}/images/gov-news/news-banner-02.png`,
      alt: 'Programa Vida Nova nas Grotas de Pernambuco',
    },
    {
      img: `${process.env.PUBLIC_URL}/images/gov-news/news-banner-03.png`,
      alt: 'Programa Cria Criança',
    },
  ];

  const tabs = [
    {
      title: 'Matérias',
      icon: <NewspaperIcon />,
      iconPosition: 'start' as 'start',
      content: <MaterialContentContainer />,
    },
    {
      title: 'Vídeos',
      icon: <VideocamIcon />,
      iconPosition: 'start' as 'start',
      content: <VideoContentContainer />,
    },
    {
      title: 'Podcasts',
      icon: <MicNoneIcon />,
      iconPosition: 'start' as 'start',
      content: <PodcastContentContainer />,
    },
    {
      title: 'Galeria',
      icon: <PhotoIcon />,
      iconPosition: 'start' as 'start',
      content: <GalleryContentContainer />,
    },
    {
      title: 'Tudo',
      icon: <LanguageIcon />,
      iconPosition: 'start' as 'start',
      content: <AllContentContainer />,
    },
  ];

  return (
    <div className="xvia-container">
      <TitleSection
        titleSettings={{
          title: 'Notícias',
          colorTitle: '#202020',
          subtitle: 'Acompanhe tudo o que esta acontecendo no nosso Estado',
          colorSubtitle: '#7B7B7B',
        }}
        iconSettings={{
          displayIcon: true,
          sectionIcon: <NewspaperIcon />,
          iconColor: '#9E9E9E',
          iconSize: '40px',
        }}
        buttonSeeMoreSettings={{
          buttonLabel: 'Ver todas',
          buttonIcon: <RemoveRedEyeIcon />,
        }}
      />
      <div className="xvia-gov-news__container_slider_imgs">
        <Slider {...settings}>
          {govPrograms.map((program, index) => {
            return (
              <div key={index} className="">
                <div className="xvia-gov-news__slider_imgs">
                  <img src={program.img} alt={program.alt} />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="xvia-gov-news__container_tabs_content">
        <TabsContent tabs={tabs} />
      </div>
    </div>
  );
};
